import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { users } from './users.reducer';
import { alert } from './alert.reducer';
import { clients } from './clients.reducer';
import { display } from './display.reducer';
import { standardReport } from './reports.reducer';
import { scoreCards } from './scorecards.reducer';
import { devices } from './devices.reducer';
import { widgets } from './widgets.reducer';
import { externalServiceTools } from './external-service-tools.reducer';
import { adminTools } from './admin-tools.reducer';
import { documentTools } from './document.reducer';
import { reducer as formReducer } from 'redux-form'
import { clientusers } from './clientusers.reducer';
import { contractor } from './contractor.reducer';
import { importdevices } from './importdevices.reducer';
import { elevatorConsultingCompany } from './elevator-consulting-company.reducer';
import { buildings } from './buildings.reducer';
import { buildingOwner } from './buildingowner.reducer';

const rootReducer = combineReducers({
  authentication,
  users,
  clients,
  alert,
  display,
  standardReport,
  scoreCards,
  devices,
  form: formReducer,
  widgets,
  externalServiceTools,
  adminTools,
  documentTools,
  clientusers,
  elevatorConsultingCompany,
  contractor,
  documentTools,
  buildings,
  contractor,
  elevatorConsultingCompany,
  buildingOwner,
  importdevices
});

export default rootReducer;