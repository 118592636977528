import config from '../config';
import { authHeader } from '../_helpers';
import { objectToFormData } from '../_helpers';

export const clientService = {
    getAll,
    getOne,
    getFields,
    saveClient,
    deleteClient
};

function getOne(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}client/${id}`, requestOptions).then(handleResponse);

}

function getFields() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}client/fields`, requestOptions).then(handleResponse);

}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}client`, requestOptions).then(handleResponse);
}

function saveClient(client = null) {
  var myHeaders = new Headers();
  myHeaders.append(Object.keys(authHeader())[0], authHeader()[Object.keys(authHeader())[0]]);
  const formdata = objectToFormData(client);

  // Normalize boolean values in FormData
  if (client) {
    Object.keys(formdata).forEach(d => {
      if (typeof formdata[d] === "boolean") {
        formdata[d] = formdata[d] ? 1 : 0;
      }
    });
  }

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formdata,
    redirect: 'follow'
  };

  // Use client.id if it exists and is not 'new'
  const apiUrl = client && client.id && client.id !== 'new'
    ? `${config.apiUrl}client/${client.id}`
    : `${config.apiUrl}client`;

  return fetch(apiUrl, requestOptions)
    .then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

function deleteClient(client_id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}client/${client_id}`, requestOptions).then(handleResponse);
}
