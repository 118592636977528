import { scoreCardsConstants } from '../_constants';

export function scoreCards(state = [], action) {
    switch (action.type) {
        case scoreCardsConstants.CRITERIA_REQUEST:
            return {
                loading: true,
                report: [],
                filter_fields: [],
                colour_codes: []
            };
        case scoreCardsConstants.CRITERIA_SUCCESS:
            return { ...state, filter_fields: action.criteria, loading: false };

        case scoreCardsConstants.CRITERIA_FAILURE:
            return {
                error: action.message
            };

        case scoreCardsConstants.MONTHLY_FIELDS:
            return { ...state, monthlyFields: action.fields };

        case scoreCardsConstants.QUARTERLY_FIELDS:
            return { ...state, quarterlyFields: action.fields };

        case scoreCardsConstants.COLOUR_CODES_REQUEST:
            return {
                loading: true,
                items: [],
                filter_fields: [],
                colour_codes: []
            };
        case scoreCardsConstants.COLOUR_CODES_SUCCESS:
            return { ...state, colour_codes: action.colour_codes, loading: false };

        case scoreCardsConstants.COLOUR_CODES_FAILURE:
            return {
                error: action.message
            };
        case scoreCardsConstants.MONTHY_REQUEST:
            return {
                loading: true,
                report: [],
                filter_fields: [],
                colour_codes: []
            };
        case scoreCardsConstants.MONTHY_SUCCESS:
            return { ...state, report: action.report, loading: false };

        case scoreCardsConstants.MONTHY_FAILURE:
            return {
                error: action.message
            };

        case scoreCardsConstants.EXPORT_FILE_REQUEST:
            return {
                loading: true,
                items: [],
                filter_fields: [],
                colour_codes: [],
                pdf: '',
                xls: '',
                allXls: ''
            };
        case scoreCardsConstants.EXPORT_FILE_SUCCESS:
            return { ...state, pdf: action.file.pdf ? action.file.pdf : '', xls: action.file.xls ? action.file.xls : '', allXls: action.file.allXls ? action.file.allXls : '', loading: false };

        case scoreCardsConstants.COLOUR_CODES_FAILURE:
            return {
                error: action.message
            };

        default:
            return state
    }
}