import { displayActions } from 'src/_actions';
import { displayConstants } from '../_constants';

export function display(state = {}, action) {
    switch (action.type) {

        case displayConstants.SET_SIDEBAR:
            return { ...state, sidebar: action.sidebar }

        case displayConstants.SET_CLIENT_CONTEXT_SUCCESS:
            return { ...state, clientContext: action.clientId }

        case displayConstants.SET_CLIENT_CONTEXT_FAILURE:
            return { error: action.message };

        case displayConstants.SET_STANDARD_REPORT_REQ_SUCCESS:
            return { ...state, standardReportRequestFields: action }

        case displayConstants.SET_STANDARD_REPORT_REQ_FAILURE:
            return { error: action.message };

        case displayConstants.SET_CALLINGLIST_REPORT_REQ_SUCCESS:
            return { ...state, callingListReportRequestFields: action }

        case displayConstants.SET_CALLINGLIST_REPORT_REQ_FAILURE:
            return { error: action.message };

        default:
            return state
    }
}


