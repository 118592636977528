import config from '../config';
import { authHeader } from '../_helpers';

export const userService = {
    login,
    logout,
    getAll,
    getOne,
    getProfile,
    saveSettings
};

function login(username, password) {

    const data = new URLSearchParams();
    data.append('username', username);
    data.append('password', password);

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: data
    };

    return fetch(`${config.apiUrl}login`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));
            let uiSettings = {};

            if (user.profile) {
                if (user.profile.hasOwnProperty('ui_settings')) {
                    uiSettings = user.profile.ui_settings != '' ? JSON.parse(user.profile.ui_settings) : {};
                }
            }

            if (uiSettings) {
                Object.keys(uiSettings).map(s => {
                    localStorage.setItem(s, uiSettings[s]);
                });
            }
            
             return user;
           
        });
}

function saveSettings(userId, settings) {

    const authHeaders = new Headers();
    authHeaders.append('Content-Type', 'application/json');
    authHeaders.append(Object.keys(authHeader())[0], authHeader()[Object.keys(authHeader())[0]]);

    const requestOptions = {
        method: 'POST',
        headers: authHeaders,
        body: settings
    };

    return fetch(`${config.apiUrl}profile-settings/${userId}`, requestOptions)
        .then(handleResponse)
        .then(settings => {
       
             return settings;
           
        });
}

function logout(token) {
    localStorage.removeItem('user');
    const data = new URLSearchParams();
    data.append('token', token);

    if (token) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: data
        };
    
    
        return fetch(`${config.apiUrl}logout`, requestOptions);
    }
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}user`, requestOptions).then(handleResponse);
}

function getOne( user_id ) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}user/${user_id}`, requestOptions).then(handleResponse);
}

function getProfile( token = null ) {
    const requestOptions = {
        method: 'GET',
        headers: !token ? authHeader() : {'Authorization': 'Bearer ' + token}
    };

    return fetch(`${config.apiUrl}profile`, requestOptions).then(handleResponseForProfile);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!data) {
            logout();
           // window.location.href="/login";
        }

        if (!response.ok) {
            if (data.status === 401 || data.status === 403 || data.status === 500) {
                // auto logout if 401 response returned from api
                logout();
               // window.location.href="/login";
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        } else {
            if (data.status === 401 || data.status === 403  || data.status === 500) {
                // auto logout if 401 response returned from api
                logout();
              //  window.location.href="/login";
            }
        }
        return data;
    }).catch(eee => {
        if (typeof eee === 'string') {
            if (eee.includes('Unexpected')) {
                logout();
              //  window.location.href="/login";
            }
        }

        if (eee === 'Unauthorized') {
            logout();
          //  window.location.href="/login";
        }
        const error = (eee && eee.message) || eee.statusText;
        return Promise.reject(eee);
       
    });
}

function handleResponseForProfile(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!data || data.status === 401 || data.status === 403  || data.status === 500) {
      localStorage.removeItem('user');
      window.location.href="/login";
    }
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    } 
    return data;
  }).catch(eee => {
      localStorage.removeItem('user');
      window.location.href="/login";
  });
}