import { widgetsConstants } from '../_constants';
import { widgetsService } from '../_services';
import { alertActions } from '.';

import store from '../store';

export const widgetsActions = {
    all,
    deleteWidget
};

function all(clientId) {
    return dispatch => {
        dispatch(request());

        widgetsService.getAll(clientId)
            .then(
                widgets =>  { 
                    dispatch(success(widgets))
                },
                error => { 
                    dispatch(failure(error));

                    if (error === 'Unauthorized.') {
                        window.location.href="/login";
                    }
                    else {
                        dispatch(alertActions.error(error));
                    }
                }
            );
    };

    function request() { return { type: widgetsConstants.WIDGETS_FETCH_REQUEST } }
    function success(widgets) { return { type: widgetsConstants.WIDGETS_FETCH_SUCCESS, ...widgets } }
    function failure(error) { return { type: widgetsConstants.WIDGETS_FETCH_FAILURE, error } }
}

function deleteWidget(clientId, widgetId) {
    return dispatch => {
        dispatch(request());

        widgetsService.deleteWidget(clientId, widgetId)
            .then(
                widgets =>  { 
                    console.log(widgets);
                },
                error => { 
                    dispatch(failure(error));

                    if (error === 'Unauthorized.') {
                        window.location.href="/login";
                    }
                    else {
                        dispatch(alertActions.error(error));
                    }
                }
            );
    };

    function request() { return { type: widgetsConstants.WIDGETS_FETCH_REQUEST } }
    function failure(error) { return { type: widgetsConstants.WIDGETS_FETCH_FAILURE, error } }
}

