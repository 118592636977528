import config from '../config';
import { authHeader, objectToFormData } from '../_helpers';
import qs from 'qs'
import axios from 'axios'

export const reportsService = {
    getStandardReport,
    getStandardReportCriteria,
    getCallListingReport,
    getCallListingReportCriteria,
    getDrillDown,
    getCallback,
    saveCallback,
    deleteCallback
};


function getStandardReport(request) {
    const authHeaders = new Headers();
    authHeaders.append(Object.keys(authHeader())[0], authHeader()[Object.keys(authHeader())[0]]);

    const formdata = objectToFormData(request);

    var requestOptions = {
        method: 'POST',
        headers: authHeaders,
        body: formdata,
        redirect: 'follow'
    };

    return fetch(`${config.apiUrl}KJAStandardReporting`, requestOptions).then(handleResponse);
}


function getDrillDown(request) {
    const authHeaders = new Headers();
    authHeaders.append(Object.keys(authHeader())[0], authHeader()[Object.keys(authHeader())[0]]);

    const formdata = objectToFormData(request);

    var requestOptions = {
        method: 'POST',
        headers: authHeaders,
        body: formdata,
        redirect: 'follow'
    };

    return fetch(`${config.apiUrl}KJAStandardReporting/drilldown`, requestOptions).then(handleResponse);
}

function getCallback(callback) {
    const authHeaders = new Headers();
    authHeaders.append(Object.keys(authHeader())[0], authHeader()[Object.keys(authHeader())[0]]);

    var requestOptions = {
        method: 'GET',  
        headers: authHeaders
    };
    return fetch(`${config.apiUrl}KJAStandardReporting/callback/${callback}`, requestOptions).then(handleResponse);
}

function getStandardReportCriteria(reqest, clientId) {
    const authHeaders = new Headers();
    authHeaders.append(Object.keys(authHeader())[0], authHeader()[Object.keys(authHeader())[0]]);

    const formdata = objectToFormData(reqest);

    var requestOptions = {
        method: 'GET',
        headers: authHeaders
    };
    return fetch(`${config.apiUrl}KJAStandardReporting/criteria/${clientId}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
   
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}


function saveCallback(callback, callback_id) {
   
    const headers = authHeader();
    headers['Content-Type'] = 'application/x-www-form-urlencoded';

    // var requestOptions = {
    // method: 'PUT',
    // headers: myHeaders,
    // body: formdata
    // };

    return axios({
        method: 'post',
        url: `${config.apiUrl}KJAStandardReporting/callback/${callback_id}`,
        data: qs.stringify(callback),
        headers: headers
      })
 

}

function deleteCallback(callback_id) {

}

function getCallListingReportCriteria(clientId) {
    const authHeaders = new Headers();
    authHeaders.append(Object.keys(authHeader())[0], authHeader()[Object.keys(authHeader())[0]]);

    var requestOptions = {
        method: 'GET',
        headers: authHeaders
    }
    
    return fetch(`${config.apiUrl}KJAStandardReporting/call-listing-criteria/${clientId}`, requestOptions).then(handleResponse);
}

function getCallListingReport(request) {
    const authHeaders = new Headers();
    authHeaders.append(Object.keys(authHeader())[0], authHeader()[Object.keys(authHeader())[0]]);

    const formdata = objectToFormData(request);
    
    var requestOptions = {
        method: 'POST',
        headers: authHeaders,
        body: formdata,
        redirect: 'follow'
    };

    return fetch(`${config.apiUrl}KJAStandardReporting/call-listing`, requestOptions).then(handleResponse);
}

