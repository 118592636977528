import { devicesConstants } from '../_constants';

export function devices(state = [], action) {
    switch (action.type) {
        case devicesConstants.UPLOAD_REQUEST:
            return {
                loading: true,
                deviceData: []
            };
        case devicesConstants.UPLOAD_SUCCESS:
            return { ...state, deviceData: action.deviceData, loading: false };

        case devicesConstants.UPLOAD_FAILURE:
            return {
                error: action.message
            };

        case devicesConstants.RENDER_RESULTS:
                return { ...state, results: action.results, loading: false };

        case devicesConstants.SUBMISSION_REQUEST:
            return { ...state,  loading: true, status: [] };

        case devicesConstants.SUBMISSION_SUCCESS:
                return { ...state,  loading: false, status: action };

        case devicesConstants.SUBMISSION_FAILURE:
                return {
                    error: action.message
                };

        default:
            return state
    }
}