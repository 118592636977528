export const contractorConstants = {
    GETALL_REQUEST: 'CONTRACTOR_GETALL_REQUEST',
    GETALL_SUCCESS: 'CONTRACTOR_GETALL_SUCCESS',
    GETALL_FAILURE: 'CONTRACTOR_GETALL_FAILURE',
    
    GETONE_REQUEST: 'CONTRACTOR_GETONE_REQUEST',
    GETONE_SUCCESS: 'CONTRACTOR_GETONE_SUCCESS',
    GETONE_FAILURE: 'CONTRACTOR_GETONE_FAILURE',

    UPDATE_SUCCESS: 'CONTRACTOR_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'CONTRACTOR_UPDATE_FAILURE',

    NEW_SUCCESS: 'CONTRACTOR_NEW_SUCCESS',
    NEW_FAILURE: 'CONTRACTOR_NEW_FAILURE',

    DELETE_REQUEST: 'CONTRACTOR_DELETE_REQUEST',
    DELETE_SUCCESS: 'CONTRACTOR_DELETE_SUCCESS',
    DELETE_FAILURE: 'CONTRACTOR_DELETE_FAILURE',
};