import config from '../config';
import { preparePostRequest, handleResponse, authHeader } from '../_helpers';

export const adminToolsService = {
    clientReportCriteria,
    clientReport
};


function clientReportCriteria() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}clientReport/criteria`, requestOptions).then(handleResponse);
}

function clientReport(request, key) {
    const requestOptions = preparePostRequest(request);

    return fetch(`${config.apiUrl}clientReport`, requestOptions).then(handleResponse);
}


