import { reportsConstants } from '../_constants';
import { reportsService } from '../_services';
import { alertActions } from '.';

import store from '../store';

export const reportsActions = {
    getStandardReport,
    getStandardReportCriteria,
    getCallListingReport,
    getCallListingReportCriteria,
    getDrillDown,
    getCallback,
    saveCallback,
    deleteCallback,
    setFields
};

function getStandardReport(req) {
    return dispatch => {
        dispatch(request());
        reportsService.getStandardReport(req)
            .then(
                report => {
                    if (!report.error) {
         
                        if (Object.keys(report).length > 0) {
                            if (report.report) {
                                if (Object.keys(report.report).length > 0) {
                                    dispatch( setFields( Object.keys( report.report[Object.keys(report.report)[0]] ), reportsConstants.STANDARD_REPORT_FIELDS ) );
                                }
                            }
                        }
                        dispatch(success(report))
                    } else {
            
                        if (report.error.includes('Unauthorized') || report.error.includes('Failed to fetch')) {
                            window.location.href="/login";
                        }
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error))
         
                    if (error === 'Unauthorized.' || error == 'TypeError: Failed to fetch') {
                        window.location.href="/login";
                    }
                }
            );
    };

    function request() { return { type: reportsConstants.STANDARD_REPORT_REQUEST } }
    function success(report) { return { type: reportsConstants.STANDARD_REPORT_SUCCESS, report } }
    function failure(error) { return { type: reportsConstants.STANDARD_REPORT_FAILURE, error } }
}

function setFields(fields, type) {
    
    fields = fields.filter(f => {
        if (typeof f !== 'object') {
            return !f.includes('_hidden')
        } else {
            return f.key.includes('_hidden');
        }
    });
 
    if ( fields.indexOf('edit') === -1 ) {
        fields.push('edit');
    }
    
    return { type,  fields }
}

function getDrillDown(req) {
    return dispatch => {
        dispatch(request());
        reportsService.getDrillDown(req)
            .then(
                report => {
                    if (!report.error) {
                        if (Object.keys(report).length > 0) {
                       
                            if (Object.keys(report.report).length > 0) {
                                dispatch( setFields( Object.keys( report.report[Object.keys(report.report)[0]] ), reportsConstants.STANDARD_REPORT_DRILLDOWN_FIELDS ) );
                            }
                        }
                        dispatch(success(report))
                    } else {
                   
                        if (report.error.includes('Unauthorized')) {
                            window.location.href="/login";
                        }
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error))

                    if (error === 'Unauthorized.') {
                        window.location.href="/login";
                    }
                }
            );
    };

    function request() { return { type: reportsConstants.STANDARD_REPORT_REQUEST } }
    function success(report) { return { type: reportsConstants.STANDARD_REPORT_DRILL_DOWN_SUCCESS, report } }
    function failure(error) { return { type: reportsConstants.STANDARD_REPORT_DRILL_DOWN_FAILURE, error } }
}

function getCallback(callback) {
    return dispatch => {
        // dispatch(request());
        reportsService.getCallback(callback)
            .then(
                result => {
                    if (!result.error) {
                        if (Object.keys(result).length > 0) {
                       
                            // if (Object.keys(callback.report).length > 0) {
                            //     dispatch( setFields( Object.keys( report.report[Object.keys(report.report)[0]] ), reportsConstants.STANDARD_REPORT_DRILLDOWN_FIELDS ) );
                            // }
                        }
                        dispatch(success(result))
                    } else {
                   
                        if (result.error.includes('Unauthorized')) {
                            window.location.href="/login";
                        }
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error))

                    if (error === 'Unauthorized.') {
                        window.location.href="/login";
                    }
                }
            );
    };

    function request() { return { type: reportsConstants.STANDARD_REPORT_REQUEST } }
    function success(result) { return { type: reportsConstants.STANDARD_REPORT_DRILL_DOWN_CALLBACK_SUCCESS, result } }
    function failure(error) { return { type: reportsConstants.STANDARD_REPORT_DRILL_DOWN_CALLBACK_FAILURE, error } }
}

function saveCallback(callback, callback_id) {
    return dispatch => {
        if (!callback) dispatch(failure(callback));
        reportsService.saveCallback(callback, callback_id)
        .then(
            callback =>  {
                dispatch(success(callback)); 
                dispatch(alertActions.success(callback.data.message));
                const state = store.getState();
                if (state.standardReportDrillDown) {
                    if ( state.standardReportDrillDown.request ) {
                       dispatch(getDrillDown(state.standardReportDrillDown.request ));
                     
                    }
                }
            }, 
            error => { 
                dispatch(failure(error));
                dispatch(alertActions.error(error))

                if (error === 'Unauthorized.') {
                    // window.location.href="/login";
                }
            }
        );

        function success(callback) { return { type: reportsConstants.CALLBACK_SET_EDIT_SUCCESS, callback } }
        function failure(error) { return { type: reportsConstants.CALLBACK_SET_EDIT_FAILURE, error } }
    }

}


function deleteCallback(callback_id) {

}

function getStandardReportCriteria(request, clientId) {
    return dispatch => {
        dispatch(request());

        reportsService.getStandardReportCriteria(request, clientId)
            .then(
                filter_fields => dispatch(success(filter_fields)),
                error => {
                    dispatch(failure(error));
                    // dispatch(alertActions.error(error))

                    if (error === 'Unauthorized.') {
                        window.location.href="/login";
                    }
                }
            );
    };

    function request() { return { type: reportsConstants.STANDARD_REPORT_CRITERIA_REQUEST } }
    function success(fields) { return { type: reportsConstants.STANDARD_REPORT_CRITERIA_SUCCESS, filter_fields: fields } }
    function failure(error) { return { type: reportsConstants.STANDARD_REPORT_CRITERIA_FAILURE, error } }
}

function getCallListingReportCriteria(clientId) {
    return dispatch => {
        dispatch(request());

        reportsService.getCallListingReportCriteria(clientId)
            .then(
                filter_fields => dispatch(success(filter_fields)),
                error => {
                    dispatch(failure(error));
                    // dispatch(alertActions.error(error))

                    if (error === 'Unauthorized.') {
                        window.location.href="/login";
                    }
                }
            );
    };

    function request() { return { type: reportsConstants.CALLLISTING_REPORT_CRITERIA_REQUEST } }
    function success(fields) { return { type: reportsConstants.CALLLISTING_REPORT_CRITERIA_SUCCESS, filter_fields: fields } }
    function failure(error) { return { type: reportsConstants.CALLLISTING_REPORT_CRITERIA_FAILURE, error } }
}

function getCallListingReport(filter) {
    return dispatch => {
        dispatch(request());
        reportsService.getCallListingReport(filter)
            .then(
                report => {
                    if (!report.error) {
                        if (Object.keys(report).length > 0) {
                   
                            if (Object.keys(report.columns).length > 0) {
                                const fields = JSON.parse(JSON.stringify(report.columns));
                                dispatch(columnfields(fields));
                            }
                        }
                        dispatch(success(report))
                    } else {
               
                        if (report.error.includes('Unauthorized')) {
                            window.location.href="/login";
                        }
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error))

                    if (error === 'Unauthorized.') {
                        window.location.href="/login";
                    }
                }
            );
    };

    function request() { return { type: reportsConstants.CALLLISTING_REPORT_REQUEST } }
    function columnfields(fields) { return { type: reportsConstants.CALLLISTING_REPORT_DRILL_DOWN_FIELDS, fields} }
    function success(report) { return { type: reportsConstants.CALLLISTING_REPORT_DRILL_DOWN_SUCCESS, report } }
    function failure(error) { return { type: reportsConstants.CALLLISTING_REPORT_DRILL_DOWN_FAILURE, error } }
}