import { devicesConstants } from '../_constants';
import { devicesService } from '../_services';
import { alertActions } from '.';

import store from '../store';

export const devicesActions = {
    upload,
    renderResult,
    submit
};

function upload(req) {
    return dispatch => {
        dispatch(request());
        devicesService.upload(req)
            .then(
                devicesData => {
                    if (!devicesData.error) {
                        dispatch(success(devicesData));
                    } else {
                        if (devicesData.error.includes('Unauthorized') || devicesData.error.includes('Failed to fetch') || devicesData.has_error) {
                             // window.location.href="/login";
                        }
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error))
                    // if (error === 'Unauthorized.' || error == 'TypeError: Failed to fetch') {
                    //     window.location.href="/login";
                    // }
                }
            );
    };

    function request() { return { type: devicesConstants.UPLOAD_REQUEST } }
    function success(devicesData) { return { type: devicesConstants.UPLOAD_SUCCESS, devicesData } }
    function failure(error) { return { type: devicesConstants.UPLOAD_FAILURE, error } }
}


function submit(req, key) {
    return dispatch => {
        dispatch(request());
        devicesService.submit(req, key)
            .then(
                devicesData => {
                    if (!devicesData.error) {
                        dispatch(success(devicesData));
                    } else {
                        if (devicesData.error.includes('Unauthorized') || devicesData.error.includes('Failed to fetch') || devicesData.has_error) {
                             // window.location.href="/login";
                        }
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error))
                    if (error === 'Unauthorized.' || error == 'TypeError: Failed to fetch') {
                        // window.location.href="/login";
                    }
                }
            );
    };

    function request() { return { type: devicesConstants.SUBMISSION_REQUEST } }
    function success(devicesData) { return { type: devicesConstants.SUBMISSION_SUCCESS, devicesData } }
    function failure(error) { return { type: devicesConstants.SUBMISSION_FAILURE, error } }
}


function renderResult(results) {
    return dispatch => {
        dispatch(success(results))
    };
    function success(results) { return { type: devicesConstants.RENDER_RESULTS, results } }
}