import { useSelector } from "react-redux";

export const canAccessKJAAdmin = (auth) => {
    if (auth) {
        if (auth.user) {
            if (auth.user.profile) {
                return (auth.user.profile.is_super_admin === 1);
            }
        }
    }
    return false;
}

export const canEditDeviceGroups = (auth) => {
    if (auth) {
        if (auth.user) {
            if (auth.user.profile) {
              const userGroup = auth.user.profile.user_group_id;
                if ([1,2,8].indexOf(userGroup) > -1) {
                    return true;
                }
            }
        }
    }
    return false;
}

export const canAccessDocuments = (auth) => {
    try {
      return (auth.user.permissions.document.read === 1 ||
        auth?.user?.profile?.user_group_id === 1 ||
        auth?.user?.profile?.user_group_id === 2 ||
        auth?.user?.profile?.user_group_id === 3);
    }
    catch (e) {
    }

    // always fail if anything goes wrong
    return false;
}

export const canEditDocuments = (auth) => {
    try {
      return (auth?.user?.permissions?.document?.update === 1 ||
        auth?.user?.profile?.user_group_id === 1 ||
        auth?.user?.profile?.user_group_id === 2 ||
        auth?.user?.profile?.user_group_id === 3);
    }
    catch (e) {
    }

    // always fail if anything goes wrong
    return false;
}

export const canAddDocuments = (auth) => {
    try {
      return (auth?.user?.permissions?.document?.create === 1 ||
        auth?.user?.profile?.user_group_id === 1 ||
        auth?.user?.profile?.user_group_id === 2 ||
        auth?.user?.profile?.user_group_id === 3);
    }
    catch (e) {
    }

    // always fail if anything goes wrong
    return false;
}

export const canDeleteDocuments = (auth) => {
    try {
      return (auth?.user?.permissions?.document?.delete === 1 ||
          auth?.user?.profile?.user_group_id === 1);
    }
    catch (e) {
    }

    // always fail if anything goes wrong
    return false;
}

export const canAccessClientUser = (auth) => (
  auth?.user?.permissions?.clientUser?.read === 1 ||
  auth?.user?.profile?.user_group_id === 1
);

export const canAddClientUser = (auth) => (
  auth?.user?.permissions?.clientUser?.create === 1 ||
  auth?.user?.profile?.user_group_id === 1 ||
  auth?.user?.profile?.user_group_id === 3
  // temp add access for kja viewer
);

export const canEditClientUser = (auth) => (
  auth?.user?.permissions?.clientUser?.update === 1 ||
  auth?.user?.profile?.user_group_id === 1 ||
  auth?.user?.profile?.user_group_id === 3
  // temp add access for kja viewer
);

export const canDeleteClientUser = (auth) => (
  auth?.user?.permissions?.clientUser?.delete === 1 ||
  auth?.user?.profile?.user_group_id === 1 ||
  auth?.user?.profile?.user_group_id === 3
  // temp add access for kja viewer
);

export const canAccessClientDeviceType = (auth) => (
  auth?.user?.permissions?.deviceType?.read === 1 ||
  auth?.user?.profile?.user_group_id === 1
);

export const canAccessDeviceAttributes = (auth) => (
  auth?.user?.permissions?.deviceType?.read === 1 &&
  auth?.user?.profile?.user_group_id === 3
);

export const canEditClientComplex = (auth) => (
  auth?.user?.permissions?.complex?.update === 1 ||
  auth?.user?.profile?.user_group_id === 1 ||
  auth?.user?.profile?.user_group_id === 2
  // add access for kja manager
);

export const canAccessContractors = (auth) => (
  auth?.user?.permissions?.contractor?.read === 1 ||
  auth?.user?.profile?.user_group_id === 2
  // add access for kja manager
);

export const canCRUClientPortfolio = (auth) => (
  auth?.user?.permissions?.portfolio?.create === 1 ||
  auth?.user?.permissions?.portfolio?.update === 1 ||
  auth?.user?.profile?.user_group_id === 1 ||
  auth?.user?.profile?.user_group_id === 2
);

export const canCRUDClientComplex = (auth, create = false, read = false, update = false, del = false) => {
  if (create) {
    return auth?.user?.permissions?.complex?.create;
  }
  else if (read) {
    return auth?.user?.permissions?.complex?.read;
  }
  else if (update) {
    return auth?.user?.permissions?.complex?.update;
  }
  else if (del) {
    return auth?.user?.permissions?.complex?.delete;
  }

  return false;
}

export const canCRUDDeviceGroup = (auth, create = false, read = false, update = false, del = false) => {
  if (create) {
    return auth?.user?.permissions?.deviceGroup?.create;
  }
  else if (read) {
    return auth?.user?.permissions?.deviceGroup?.read;
  }
  else if (update) {
    return auth?.user?.permissions?.deviceGroup?.update;
  }
  else if (del) {
    return auth?.user?.permissions?.deviceGroup?.delete;
  }

  return false;
}

export const isKJAAdmin = (auth) => (
  auth?.user?.profile?.user_group_id === 2
);
