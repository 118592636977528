import React, { useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux'
import { displayActions, userActions } from '../_actions';

export const PrivateRoute = ({ component: Component, ...rest } ) =>  {
    const qs = queryString.parse( rest.location.search );
    let token = false;
    const dispatch = useDispatch();

    if (qs.token) {
        token = qs.token;
        if ( qs.client ) {
            dispatch(displayActions.setClientIdContext(qs.client));
        }
        dispatch(userActions.loginWithToken( token ));
    } 

    return (
    
        <Route {...rest} render={props =>  {
            return (
            localStorage.getItem('user') || token
                ? <Component {...props} />
                : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            )
        }
        } />
    )

}
