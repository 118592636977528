import { contractorConstants } from '../_constants/contractor.constants';

export function contractor(state = {}, action) {

    switch (action.type) {
        case contractorConstants.GETALL_REQUEST:
            return {
                loading: true,
                items: []
            };
        case contractorConstants.GETALL_SUCCESS:
            return { items: action.contractors, loading: false };
        case contractorConstants.GETALL_FAILURE:
            return {
                error: action.error, loading: false
            };

        case contractorConstants.GETONE_REQUEST:
            return {
                loading: true,
                items: []
            };
        case contractorConstants.GETONE_SUCCESS:
            return { contractor: action.contractor };
        case contractorConstants.GETONE_FAILURE:
            return {
                error: action.error
            };

        case contractorConstants.UPDATE_SUCCESS:
            return { contractor: action.contractor, success: true };
        case contractorConstants.UPDATE_FAILURE:
            return {
                error: action.error, success: false
            };

        case contractorConstants.NEW_SUCCESS:
            return { new_id:action.createnew.id, success: true };
        case contractorConstants.NEW_FAILURE:
            return {
                error: action.error, success: false
            };

        case contractorConstants.DELETE_REQUEST:
            return {
                loading: true,
            };
        case contractorConstants.DELETE_SUCCESS:
            return { deletecontractor: action.deletecontractor, loading: false, };
        case contractorConstants.DELETE_FAILURE:
            return {
                error: action.error, loading: false
            }; 
 
        default:
            return state;
    }
}
