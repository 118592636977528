export default { 
    apiUrl: process.env.REACT_APP_API_URL,
    clientRequiredFields: [
        'active',
        //'postal_code',
        //'province_id',
        //'country_id',
        //'phone_number',
        //'consultant_id',
        //'client_type_id',
        //'use_critical_unit',
        //'hide_contract_types',
        //'auto_insert_maintenance_data'
    ],
    userRequiredFields: [],
    userFields: {
            'user_id': {'type': 'hidden', 'label': 'User Id'},
            'username': {'type': 'text', 'label': 'Username'},
            'last_login': {'type': 'date', 'label': 'Last Login'},
            'is_super_admin': {'type': 'checkbox', 'label': 'Is Super Admin'},
            'first_name': {'type': 'text', 'label': 'First Name'},
            'last_name': {'type': 'text', 'label': 'Last Name'},
            'email': {'type': 'email', 'label': 'Email'},
            'culture': {'type': 'text', 'label': 'Language'},
            'contractor_id': {'type': 'text', 'label': 'Contractor Id'},
            'contractor_name': {'type': 'text', 'label': 'Contractor Name'},
            'client_id': {'type': 'text', 'label': 'Client Id'},
            'client_name': {'type': 'text', 'label': 'Client Name'},
            'client_logo': {'type': 'file', 'label': 'Client Logo'},
            'client_banner': {'type': 'file', 'label': 'Client Banner'},
            'user_group_id': {'type': 'text', 'label': 'User Group Id'},
            'user_group_name': {'type': 'text', 'label': 'User Group Name'}
    },
    supportedTypes: ['text', 'number', 'hidden', 'email', 'password']
}

