import CIcon from '@coreui/icons-react';
import { CSidebarNavTitle, CSidebarNavItem, CSidebarNav, CLink, CModal, CModalHeader, CModalBody, CForm, CFormGroup, CSwitch, CLabel, CModalFooter, CButton, CInput } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { alertActions, reportsActions, scoreCardsActions, userActions } from 'src/_actions';

import download from 'downloadjs'
import SearchIcon from "@mui/icons-material/Search";
import createHistory from "history/createBrowserHistory";

import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { authHeader } from './auth-header';
import axios from 'axios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExcel, faFilePdf, faFileCsv, faFileDownload } from '@fortawesome/free-solid-svg-icons'
import { faFileExcel as faFileExcelRegular } from '@fortawesome/free-regular-svg-icons'

import config from 'src/config';
import { history } from './history';

import * as yup from "yup";
import { canEditDeviceGroups } from '.';
import { faArrowAltFromTop, faArrowAltSquareDown, faArrowDownToSquare, faArrowSquareDown, faSquareArrowDown } from '@fortawesome/pro-light-svg-icons';
import { TextField } from '@mui/material';
import { IconButton } from 'rsuite';

export const objectToFormData = (obj, form, namespace) => {

  var fd = form || new FormData();
  var formKey;

  for (var property in obj) {
    if (obj.hasOwnProperty(property)) {

      if (namespace) {
        formKey = namespace + '[' + property + ']';
      } else {
        formKey = property;
      }

      // if the property is an object, but not a File,
      // use recursivity.
      if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {

        objectToFormData(obj[property], fd, property);

      } else {

        // if it's a string or a File object
        fd.append(formKey, obj[property]);
      }

    }
  }

  return fd;

};

export const objExists = (obj) => {
  if (!obj) return false;

  if (Object.keys(obj).length === 0) return false;

  return true;
}

export const slugify = (string) => {
  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
  const p = new RegExp(a.split('').join('|'), 'g')

  return string.toString().toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    // .replace(/s([^s]*)$/, '$1')
    // .replace(/es([^es]*)$/, '$1')
    .replace(/-+$/, '') // Trim - from end of text
}

export const Tree = (props) => {
  const data = props.data;
  return (
    <CSidebarNav className="c-sidebar-nav h-100 ps">
      {data.map((item, i) => {
        let prevObject = data[i - 1];
        let styleObj = { paddingLeft: (item.depth - 1) * 20 + 20 };
        let className = props.active === item.to ? " c-sidebar-nav-link " : " c-sidebar-nav-link c-active ";
        className += item.depth === 1 && data[i + 1].depth === 2 ? ' is-nav-toggle ' : className;
        className += item.depth === 2 ? ' is-element-to-toggle ' : className;
        return (
          <CSidebarNavItem key={i}>
            <CLink style={styleObj} className={className} to={'/' + item.to}><CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon" /> {item.name}</CLink>

          </CSidebarNavItem>
        )
      })}
    </CSidebarNav>
  )
}

export const flatten = (array, depth, parent) => {
  return array.reduce((p, c, i, a) => {
    if (typeof c[1] === 'object' && c[1] !== null) {
      const item = { name: c[0], depth: depth };
      const flatChildren = flatten(Object.entries(c[1]), depth + 1);
      return p.concat([item]).concat(flatChildren);
    } else {
      const item = { name: c[0], depth: depth };
      return p.concat([item]);
    }
  }, [])
}

// Hook
export const useWindowSize = () => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}

export const setColumns = (event, newFields, originalFields, dispatch, type, userId = null) => {
  const field = event.target;
  const fieldVisibility = event.target.checked;

  if (!fieldVisibility) {
    newFields = newFields.filter(f => f !== field.id);
  } else {
    if (newFields.indexOf(field.id) === -1) {
      const index = originalFields.indexOf(field.id);
      newFields.splice(index, 0, field.id);
    }
  }

  localStorage.setItem('newFields' + type, JSON.stringify(newFields));

  if (type == 'STANDARD_REPORT_FIELDS') {
    dispatch(reportsActions.setFields(newFields, type));
  } else {
    dispatch(scoreCardsActions.setFields(newFields, type));
  }

  if (userId) {
    userActions.saveSettings(userId, clearSettingsBeforeSave(localStorage));
  }
}

export const ColumnPicker = (props) => {
  const [settingsModal, setsettingsModal] = useState(false);
  let report = useSelector((state) => state.standardReport.standardReport);
  let pdfLink, xlsLink, allXlsLink;

  const toggleSettingsModal = () => {
    if (props.newFields) {
      localStorage.setItem('newFields' + props.type, JSON.stringify(props.newFields));
    }
    setsettingsModal(!settingsModal);

    if (settingsModal) {
      toast.success('Selected Columns were saved.');
    }
  }

  const authentication = useSelector(state => state.authentication);
  let userId = 0;
  if (authentication.user) {
    if (authentication.user.profile) {
      userId = authentication.user.profile.user_id;
    }
  }

  const clearSettings = () => {
    let keys = Object.keys(localStorage),
      i = keys.length;

    while (i--) {
      if (keys[i].includes('WidthstandardReport') || keys[i].includes('originalSR')) {
        localStorage.removeItem(keys[i]);
      }
    }
    history.go('/standardReport')
  }

  return (
    <>
      <span title="Columns settings" >
        <CIcon className="float-right mt-2 cursor-pointer settings-pos" name="cil-settings" size={'lg'} alt="Settings" onClick={toggleSettingsModal} />
      </span>
      <span title="Clear settings" >
        <CIcon className="float-right mt-2 cursor-pointer settings-pos mr-2" name="cil-clear-all" size={'lg'} alt="Settings" onClick={clearSettings} />
      </span>

      <ExportLinks {...props} />

      <CModal
        show={settingsModal}
        onClose={toggleSettingsModal}
      >
        <CModalHeader closeButton>Selected columns will be visible in the report</CModalHeader>
        <CModalBody>
          <CForm>
            {props.fields &&
              props.fields.filter(f => f !== 'edit').map(d => {
                let on = props.newFields.indexOf(d) > -1 ? 1 : 0;

                return (
                  <CFormGroup key={d} variant="custom-checkbox" className="d-flex mt-2">
                    <CSwitch
                      className="mr-1"
                      color="primary"
                      checked={on}
                      id={d} name="selectedColumns"
                      onChange={(e) => { setColumns(e, props.newFields, props.fields, props.dispatch, props.type, userId) }}
                    />
                    <CLabel htmlFor={d}>{capitalize(d.replace('_', ' '))}</CLabel>
                  </CFormGroup>
                )
              })
            }
          </CForm>
        </CModalBody>
        <CModalFooter>
          <CButton color="primary" onClick={toggleSettingsModal}>Save</CButton>{' '}
        </CModalFooter>
      </CModal></>)
}

const recursiveDataTableCollectionFlatten = (collection, update, initialObjectKeys, clientId, args) => {
  if (!collection || typeof collection !== 'object') return;

  const collectionObjeKeys = Object.keys(collection);
  if (!collectionObjeKeys.length) return;

  collectionObjeKeys.forEach(key => {
    if (key !== '0_data' && key !== 'building_id_hidden' && key !== 'contract_type' && key !== 'contractor_id_hidden') {

      if (collection[key]) {
        let row = { office: key };
        if (!args.useOfficeKey) {
          row = { client: key }
        }

        if (key === 'contract_type') {
          row['contract_type'] = collection[key];
        }

        if (initialObjectKeys.indexOf(key) > -1) {
          row['_classes'] = 'score-card-special-row';
        }

        if (key === 'Non-Standby' || key === 'Standby') {
          row['_classes'] = 'score-card-special-row-2';
        }

        if (args.use0Data) {
          if (collection[key]['0_data']) {

            Object.keys(collection[key]['0_data']).forEach(dataKey => {
              row[dataKey] = collection[key]['0_data'][dataKey];
              const buildingId = collection[key]['building_id_hidden'];
              const contractor_id_hidden = collection[key]['contractor_id_hidden'];
              const contractType = collection[key]['contract_type'];

              if (buildingId) {
                row['building_id_hidden'] = buildingId;
                row['contract_type'] = contractType;
                if (clientId == 74) {
                }
                row['date'] = dataKey;
              }

              if (contractor_id_hidden)  {
                row['contractor_id_hidden'] = contractor_id_hidden;
              }
            });
          }
        } else {
          if (collection[key]) {
            Object.keys(collection[key]).forEach(dataKey => {
              row[dataKey] = collection[key][dataKey];
              const buildingId = collection[key]['building_id_hidden'];
              const contractType = collection[key]['contract_type'];

              if (buildingId) {
                row['building_id_hidden'] = buildingId;
                row['contract_type'] = contractType;
                if (clientId == 74) {
                }
                row['date'] = dataKey;
              }
            });
          }
        }
        update.push(row);
      }
      recursiveDataTableCollectionFlatten(collection[key], update, initialObjectKeys, clientId, args);
    }

  });

  return update;
}


export const convertToDataTableCollection = (collection, clientId, args = { 'use0Data': true, 'useOfficeKey': true }) => {
  const update = [];
  const keys = collection ? Object.keys(collection) : [];
  localStorage.setItem('scoreCardSortExclude' + clientId, keys.map(d => String(d).toLowerCase()));
  const dtCollection = recursiveDataTableCollectionFlatten(collection, update, keys, clientId, args);

  return dtCollection;
}

export const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}


export const drillDownSettingsToLocalStorage = (data, userId) => {
  saveSortingSettingsToLocalStorage('drillDown', data, userId);
}

export const standardReportSettingsToLocalStorage = (data, userId) => {
  saveSortingSettingsToLocalStorage('standardReport', data, userId);
}

export const scoreCardSettingsToLocalStorage = (data, userId) => {
  saveSortingSettingsToLocalStorage('scoreCard', data, userId);
}

export const saveSortingSettingsToLocalStorage = (type, column, userId) => {
  if (Object.keys(column).length == 0) return;

  localStorage.setItem(type + 'SortingSettings', JSON.stringify(column));
  userActions.saveSettings(userId, clearSettingsBeforeSave(localStorage));

}

export const getStandardReportSortingSettings = () => {
  return getSortingSettings('standardReport');
}

export const getScoreCardSortingSettings = () => {
  return getSortingSettings('scoreCard');
}

export const getDrilldownSortingSettings = () => {
  return getSortingSettings('drilldown');
}

export const pad = (n) => { return n < 10 ? '0' + n : n }

const getSortingSettings = (type) => {

  let sortingSettings = localStorage.getItem(type + 'SortingSettings');


  if (sortingSettings) {
    sortingSettings = JSON.parse(sortingSettings);

    return sortingSettings;
  }

  return {}
}


export const hop = (obj, key) => {
  if (obj.hasOwnProperty(key)) return true;

  return false;
}

export const cleanSettings = (settings, clientId) => {
  if (hop(settings, 'user')) {
    delete settings['user'];
  }

  if (hop(settings, 'clientContext')) {
    delete settings['clientContext'];
  }

  if (hop(settings, 'standardReportRequest-' + clientId)) {
    delete settings['standardReportRequest-' + clientId];
  }

  if (hop(settings, 'scoreCardRequest-monthly' + clientId)) {
    delete settings['scoreCardRequest-monthly' + clientId];
  }

  if (hop(settings, 'scoreCardRequest-quarterly' + clientId)) {
    delete settings['scoreCardRequest-quarterly' + clientId];
  }

  return settings;
}

export const clearSettingsBeforeSave = (local, clientId) => {
  let clonedSettings = Object.assign({}, local);
  clonedSettings = cleanSettings(clonedSettings, clientId);

  return { "data": { "meta_key": "ui_settings", "meta_value": JSON.stringify(clonedSettings) } }
}

export const loadingSpinner = (message = '') => {
  return (
    <div className="text-center">
      <div className="spinner-border text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  )
}

export const relativeUrlPath = (url) => {
  try {
    const urlObj = new URL(url);
    return urlObj.pathname.replace('/index.php', '');
  } catch {
    return url;
  }
}


export const handleDownload = (e, file, ext, originName = '') => {
  const fileName = originName || file;
  const authHeaders = new Headers();
  if (ext != 'csv') {
    authHeaders.append('Content-Type', 'application/' + ext);
  } else {
    authHeaders.append('Content-Type', 'text/csv');
  }

  authHeaders.append(Object.keys(authHeader())[0], authHeader()[Object.keys(authHeader())[0]]);

  axios(file, {
    headers: { 'Authorization': authHeader()[Object.keys(authHeader())[0]] },
    method: 'GET',
    responseType: 'blob' //Force to receive data in a Blob Format
  })
    .then(response => {
      const file = new Blob(
        [response.data],
        { type: 'application/' + ext });
      const fileURL = URL.createObjectURL(file);

      if (window.navigator.msSaveOrOpenBlob) {
        navigator.msSaveBlob(file, fileName);
      } else {
        const downloadLink = document.createElement("a");
        let extAdd = ext === 'csv' ? '.csv' : '';

        downloadLink.href = window.URL.createObjectURL(file);

        downloadLink.download = fileName.split("/").pop() + extAdd;

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
    })
    .catch(error => {
      if (error && error.response) {
        if (error.reponse.status == 401) {
          history.push('/login');
          history.go(0);
        }
      }

    });
};


export const clearUserSettingsFromLocalStorage = (type) => {
  let keys = Object.keys(localStorage),
    i = keys.length;

  while (i--) {
    if (keys[i].includes('column') || keys[i].includes('score') || (keys[i].includes('client') && keys[i] !== 'clientContext') || keys[i].includes('original') || keys[i].includes('selected') || keys[i].includes('standardReport') || keys[i].includes('scoreCard') || keys[i].includes('clientReport') || keys[i].includes('newServiceReport') || keys[i].includes('callListingReportRequest') || keys[i].includes('callListingsRequest') || keys[i].includes('drillDownSortingSettings')) {
      localStorage.removeItem(keys[i]);
    }
  }
}

export const handleResponse = (response) => {
  return response.text().then(text => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      if (data.status === 401 || data.status === 403) {
        // auto logout if 401 response returned from api
        window.location.href = "/login";
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    } else {
      if (data.status === 401 || data.status === 403) {
        // auto logout if 401 response returned from api
        window.location.href = "/login";
      }
    }
    return data;
  });
}

export const setFields = (fields, type, edit = false) => {

  fields = fields.filter(f => {
    if (typeof f !== 'object') {
      return !f.includes('_hidden') && !f.includes('id')
    } else {
      return f.key.includes('_hidden') || f.key.includes('id');
    }
  });

  if (edit) {
    if (fields.indexOf('edit') === -1) {
      fields.push('edit');
    }
  }

  return { type, fields }
}

export const renameKeys = (keysMap, obj) =>
  Object.keys(obj).reduce(
    (acc, key) => ({
      ...acc,
      ...{ [keysMap[key] || key]: obj[key] }
    }),
    {}
  );

export const setDeviceGroupKeys = (object, auth = {}) => {
  const labels = {
    'active': 'Status',
    'device_cnt': '# devices',
  };

  const canEdit = canEditDeviceGroups(auth);

  const fieldsOrder = ['active', 'group_id', 'group_name', 'building_name', 'device_cnt'];

  const hiddenFields = ['building_id', 'group_id'];

  let keys = null;

  if (typeof object === 'object' && typeof object[0] === 'object') {
      keys = Object.keys(object[0]);
      keys = keys.sort((a, b) => {
        return fieldsOrder.indexOf(a) - fieldsOrder.indexOf(b);
      });
  }

  const fields = [];

  if (keys) {
    keys.forEach(key => {
      if (hiddenFields.indexOf(key) === -1) {
        const keysObject = {}
        keysObject.key = key;

        keysObject.label = labels[key];

        fields.push(keysObject);
      }
    });
    if (canEdit) {
      fields.push({ key: 'edit', label: 'View' });
    } else {
      fields.push('view');
    }
  }

  return fields;
}


export const getStatusImages = (status) => {
  switch (status) {
    case 1: return '/images/green.png'
    case 0: return '/images/red.png'

    default: return '/images/amber.png'
  }
}

export const groupEmails = (groups, emails) => {
  if (!emails) return;
  emails.forEach(email => {
    groups[email.group_id]['emails'].push(email);
  });

  return groups;
}

export const removeFromGroups = (id, groups) => {
  Object.keys(groups).forEach(emails => {
    groups[emails]['emails'] = groups[emails]['emails'].filter(email => email.id !== id);
  }
  );

  return groups;
}

export const depthOf = (object) => {
  var level = 1;
  for (var key in object) {
    if (!object.hasOwnProperty(key)) {
      continue;
    }

    if (typeof object[key] == 'object') {
      var depth = depthOf(object[key]) + 1;
      level = Math.max(depth, level);
    }
  }
  return level;
}

export const ExportLinks = (props) => {

  return (
    <span className="export-links">
      {props.allXlsLink && <a title="Export All" className="float-right mt-2 mx-2 cursor-pointer export-icon" onClick={(e) => handleDownload(e, props.allXlsLink, 'vnd.openxmlformats-officedocument.spreadsheetml.sheet')}>  <FontAwesomeIcon size="2x" icon={faFileExcelRegular} /></a>}
      {props.xlsLink && <a className="float-right mt-2 mx-2 cursor-pointer export-icon" onClick={(e) => handleDownload(e, props.xlsLink, 'vnd.openxmlformats-officedocument.spreadsheetml.sheet')}>  <FontAwesomeIcon size="2x" icon={faFileExcel} /></a>}
      {props.pdfLink && <a className="float-right mt-2 mx-2 cursor-pointer export-icon" onClick={(e) => handleDownload(e, props.pdfLink, 'pdf')}><FontAwesomeIcon size="2x" icon={faFilePdf} /></a>}
      {props.csvLink && <a className="float-right mt-2 mx-2 cursor-pointer export-icon" onClick={(e) => handleDownload(e, props.csvLink, 'csv')}><FontAwesomeIcon size="2x" icon={faFileCsv} /></a>}
    </span>
  )
}

export const getfiletype = (filename) => {
  let filetype = '';

  if (filename) {
    let lvalue = filename.toLowerCase();

    if (lvalue.indexOf('pdf') !== -1) {
      filetype = 'pdf'
    }
    else if (lvalue.indexOf('xlsx') !== -1) {
      filetype = 'xls';
    }
    else if (lvalue.indexOf('xls') !== -1) {
      filetype = 'xls';
    }
    else if (lvalue.indexOf('csv') !== -1) {
      filetype = 'csv';
    }
  }

  return filetype;
};

export const getfileicon = (filename) => {
  let fileicon = '';

  if (filename) {
    let lvalue = filename.toLowerCase();

    if (lvalue.indexOf('pdf') !== -1) {
      fileicon = 'faFilePdf';
    }
    else if (lvalue.indexOf('xlsx') !== -1) {
      fileicon = 'fafileExcel';
    }
    else if (lvalue.indexOf('xls') !== -1) {
      fileicon = 'fafileExcel';
    }
    else if (lvalue.indexOf('csv') !== -1) {
      fileicon = 'fafileCsv';
    }
  }

  return fileicon;
};

export const getColumnChooserButton = (props) => {
  return (
    <button className="btn order-2" onClick={props.onToggle} ref={props.buttonRef}>
      <CIcon name="cil-settings" size='xl' />
    </button>
  );

}

export const getExportButton = (props) => {
  return (
    <button className="btn" onClick={props.onToggle} ref={props.buttonRef}>
      <FontAwesomeIcon size='2x' icon={faArrowDownToSquare} />
    </button>
  );

}

export const GetSearchBar = (props, func, initialVal) => {
  const [val, setVal] = useState(initialVal || '');
  return (

    <CInput
      id="search-bar"
      className="col-2"
      defaultValue={val}
      onChange={setVal}
      onKeyDown={(e) => {if (e.key === 'Enter') func(e.target.value)}}
      placeholder="Search..."
      size="small"
    />
  );

}

export const addQuery = (key, value) => {
  let pathname = window.location.pathname;
  let searchParams = new URLSearchParams(window.location.search);
  searchParams.set(key, value);
  history.push({
    pathname: pathname,
    search: searchParams.toString()
  });
};

export const removeQuery = (key) => {
  let pathname = window.location.pathname;
  let searchParams = new URLSearchParams(window.location.search);
  searchParams.delete(key);
  history.push({
    pathname: pathname,
    search: searchParams.toString()
  });
}

export const arrayUpsert = (array, item) => { // (1)
  const i = array.findIndex(_item => _item.id === item.id);
  if (i > -1) array[i] = item; // (2)
  else array.push(item);
}

export const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min);
}

export const str2bool = (value) => {
  if (value && typeof value === "string") {
    if (value.toLowerCase() === "true") return true;
    if (value.toLowerCase() === "false") return false;
  }
  return value;
}
