import { userConstants } from '../_constants';

export function users(state = {}, action) {
    switch (action.type) {
        case userConstants.GETALL_REQUEST:
            return {
                loading: true,
                items: []
            };
        case userConstants.GETALL_SUCCESS:
            return { items: action.users };
        case userConstants.SAVE_SETTINGS:
                return { settings: action.settings };
        case userConstants.GETALL_FAILURE:
            return {
                error: action.error
            };
        case userConstants.GETONE_SUCCESS:
            return { user: action.user.profile };
        case userConstants.GETONE_FAILURE:
            return {
                error: action.error
            };
        case userConstants.GETPROFILE_SUCCESS:
            return { user: action.user.profile };
        case userConstants.GETPROFILE_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}