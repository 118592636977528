import { buildingsConstants } from '../_constants';

export function buildings(state = [], action) {
    switch (action.type) {
        case buildingsConstants.UPLOAD_REQUEST:
            return { loading: true, buildingsData: [] };
        case buildingsConstants.UPLOAD_SUCCESS:
            return { ...state, buildingsData: action.buildingData, loading: false };
        case buildingsConstants.UPLOAD_FAILURE:
            return { error: action.message };

        case buildingsConstants.RENDER_CLEAR:
            return { ...state, status: {} };
        case buildingsConstants.RENDER_RESULTS:
                return { ...state, results: action.results, loading: false };

        case buildingsConstants.SUBMISSION_REQUEST:
            return { ...state,  loading: true, status: {} };

        case buildingsConstants.SUBMISSION_SUCCESS:
                return { ...state,  loading: false, status: action.buildingsData };

        case buildingsConstants.SUBMISSION_FAILURE:
                return {
                    error: action.message
                };

        default:
            return state
    }
}