import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { PrivateRoute } from './_components/PrivateRoute';
import './scss/style.scss';
import { clearUserSettingsFromLocalStorage } from './_helpers';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

function componentLoader(lazyComponent, attemptsLeft) {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error) => {
        // let us retry after 1500 ms
        setTimeout(() => {
          if (attemptsLeft === 1) {
            reject(error);
            return;
          }
          // call componentLoader again!
        }, 1500)
      })
  });
}

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const attemptsLeft = 3;

// Containers
const TheLayout = React.lazy(() => componentLoader(() => import('./containers/TheLayout')), attemptsLeft);

// Pages
const Login = React.lazy(() => componentLoader(() => import('./views/pages/login/Login')), attemptsLeft);
const Register = React.lazy(() => componentLoader(() => import('./views/pages/register/Register')), attemptsLeft);
const Page404 = React.lazy(() => componentLoader(() => import('./views/pages/page404/Page404')), attemptsLeft);
const Page500 = React.lazy(() => componentLoader(() => import('./views/pages/page500/Page500')), attemptsLeft);
const queryClient = new QueryClient();

class App extends Component {

  render() {

    return (
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
            <React.Suspense fallback={loading}>
              <Switch>
                <Route path="/login" name="Login Page" render={props => <Login {...props}/>} />
                <PrivateRoute path="/" component={TheLayout}/>
                <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
                <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
                {/* <Route path="/" name="Home" render={props => <TheLayout {...props}/>} /> */}
              </Switch>
            </React.Suspense>
        </BrowserRouter>
      </QueryClientProvider>
    );
  }
}

export default App;
