export const userConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    LOGOUT: 'USERS_LOGOUT',
    SAVE_SETTINGS: 'SAVE_SETTINGS',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',
    GETONE_REQUEST: 'USERS_GETONE_REQUEST',
    GETONE_SUCCESS: 'USERS_GETONE_SUCCESS',
    GETONE_FAILURE: 'USERS_GETONE_FAILURE',
    GETPROFILE_REQUEST: 'USERS_GETPROFILE_REQUEST',
    GETPROFILE_SUCCESS: 'USERS_GETPROFILE_SUCCESS',
    GETPROFILE_FAILURE: 'USERS_GETPROFILE_FAILURE',
    SET_CLIENTS:    'USERS_SET_CLIENTS'
};