import { buildingsConstants } from '../_constants';
import { buildingsService } from '../_services';
import { alertActions } from '.';

import store from '../store';

export const buildingsActions = {
    upload,
    renderResult,
    submit
};

function upload(req, client_id) {
    return dispatch => {
        dispatch(request());
        buildingsService.upload(req, client_id)
            .then(
                buildingsData => {
                    if (!buildingsData.error) {
                        dispatch(success(buildingsData));
                    } else {
                        if (buildingsData.error.includes('Unauthorized') || buildingsData.error.includes('Failed to fetch') || buildingsData.has_error) {
                             // window.location.href="/login";
                        }
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error))
                    // if (error === 'Unauthorized.' || error == 'TypeError: Failed to fetch') {
                    //     window.location.href="/login";
                    // }
                }
            );
    };

    function request() { return { type: buildingsConstants.UPLOAD_REQUEST } }
    function success(buildingsData) { return { type: buildingsConstants.UPLOAD_SUCCESS, buildingsData } }
    function failure(error) { return { type: buildingsConstants.UPLOAD_FAILURE, error } }
}


function submit(req, client_id, code) {
    return dispatch => {
        dispatch(request());
        buildingsService.submit(req, client_id, code)
            .then(
                buildingsData => {
                    if (!buildingsData.error) {
                        dispatch(success(buildingsData));
                    } else {
                        if (buildingsData.error.includes('Unauthorized') || buildingsData.error.includes('Failed to fetch') || buildingsData.has_error) {
                             // window.location.href="/login";
                        }
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error))
                    if (error === 'Unauthorized.' || error == 'TypeError: Failed to fetch') {
                        // window.location.href="/login";
                    }
                }
            );
    };

    function request() { return { type: buildingsConstants.SUBMISSION_REQUEST } }
    function success(buildingsData) { return { type: buildingsConstants.SUBMISSION_SUCCESS, buildingsData } }
    function failure(error) { return { type: buildingsConstants.SUBMISSION_FAILURE, error } }
}


function renderResult(results) {
    return dispatch => {
        dispatch(clear())
        dispatch(success(results))
    };
    function clear() { return { type: buildingsConstants.RENDER_CLEAR } }
    function success(results) { return { type: buildingsConstants.RENDER_RESULTS, results } }
}