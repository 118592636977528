import { buildingOwnerConstants } from '../_constants/buildingowner.constants';

export function buildingOwner(state = {}, action) {

    switch (action.type) {
        case buildingOwnerConstants.GETALL_REQUEST:
            return {
                loading: true,
                items: []
            };
        case buildingOwnerConstants.GETALL_SUCCESS:
            return { items: action.buildingowners, loading: false };
        case buildingOwnerConstants.GETALL_FAILURE:
            return {
                error: action.error, loading: false
            };

        case buildingOwnerConstants.GETONE_REQUEST:
            return {
                loading: true,
                items: []
            };
        case buildingOwnerConstants.GETONE_SUCCESS:
            return { buildingowner: action.buildingowner };
        case buildingOwnerConstants.GETONE_FAILURE:
            return {
                error: action.error
            };

        case buildingOwnerConstants.UPDATE_SUCCESS:
            return { buildingowner: action.buildingowner, success: true };
        case buildingOwnerConstants.UPDATE_FAILURE:
            return {
                error: action.error, success: false
            };

        case buildingOwnerConstants.NEW_SUCCESS:
            return { new_id:action.createnew.id, success: true };
        case buildingOwnerConstants.NEW_FAILURE:
            return {
                error: action.error, success: false
            };

        case buildingOwnerConstants.DELETE_REQUEST:
            return {
                loading: true,
            };
        case buildingOwnerConstants.DELETE_SUCCESS:
            return { deletebuildingowner: action.deletebuildingowner, loading: false, };
        case buildingOwnerConstants.DELETE_FAILURE:
            return {
                error: action.error, loading: false
            }; 
 
        default:
            return state;
    }
}
