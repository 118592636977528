export const reportsConstants = {   
    STANDARD_REPORT_REQUEST: 'STANDARD_REPORT_REQUEST',
    STANDARD_REPORT_SUCCESS: 'STANDARD_REPORT_SUCCESS',
    STANDARD_REPORT_FAILURE: 'STANDARD_REPORT_FAILURE',
    STANDARD_REPORT_FIELDS: 'STANDARD_REPORT_FIELDS',
    NEW_SERVICES_REPORT_FIELDS: 'NEW_SERVICES_REPORT_FIELDS',
    STANDARD_REPORT_DRILLDOWN_FIELDS: 'STANDARD_REPORT_DRILLDOWN_FIELDS',

    STANDARD_REPORT_CRITERIA_REQUEST: 'STANDARD_REPORT_CRITERIA_REQUEST',
    STANDARD_REPORT_CRITERIA_SUCCESS: 'STANDARD_REPORT_CRITERIA_SUCCESS',
    STANDARD_REPORT_CRITERIA_FAILURE: 'STANDARD_REPORT_CRITERIA_FAILURE',

    STANDARD_REPORT_DRILL_DOWN_SUCCESS: 'STANDARD_REPORT_DRILL_DOWN_SUCCESS',
    STANDARD_REPORT_DRILL_DOWN_FAILURE: 'STANDARD_REPORT_DRILL_DOWN_FAILURE',

    STANDARD_REPORT_DRILL_DOWN_CALLBACK_SUCCESS: 'STANDARD_REPORT_DRILL_DOWN_CALLBACK_SUCCESS',
    STANDARD_REPORT_DRILL_DOWN_CALLBACK_FAILURE: 'STANDARD_REPORT_DRILL_DOWN_CALLBACK_FAILURE',

    CALLBACK_SET_EDIT_SUCCESS: 'CALLBACK_SET_EDIT_SUCCESS',
    CALLBACK_SET_EDIT_FAILURE: 'CALLBACK_SET_EDIT_FAILURE',

    CALLLISTING_REPORT_CRITERIA_REQUEST: 'CALLLISTING_REPORT_CRITERIA_REQUEST',
    CALLLISTING_REPORT_CRITERIA_SUCCESS: 'CALLLISTING_REPORT_CRITERIA_SUCCESS',
    CALLLISTING_REPORT_CRITERIA_FAILURE: 'CALLLISTING_REPORT_CRITERIA_FAILURE',

    CALLLISTING_REPORT_REQUEST: 'CALLLISTING_REPORT_REQUEST',
    CALLLISTING_REPORT_DRILL_DOWN_FIELDS: 'CALLLISTING_REPORT_DRILL_DOWN_FIELDS',
    CALLLISTING_REPORT_DRILL_DOWN_SUCCESS: 'CALLLISTING_REPORT_DRILL_DOWN_SUCCESS',
    CALLLISTING_REPORT_DRILL_DOWN_FAILURE: 'CALLLISTING_REPORT_DRILL_DOWN_FAILURE',
    CALLLISTING_SET_COLUMN_FIELDS: 'CALLLISTING_SET_COLUMN_FIELDS',
};
