import { reportsConstants } from '../_constants';

export function standardReport(state = [], action) {

    switch (action.type) {
        case reportsConstants.STANDARD_REPORT_CRITERIA_REQUEST:
            return {
                loading: true,
                items: [],
                standardReportDrilldownCallback: {}
            };
        case reportsConstants.STANDARD_REPORT_CRITERIA_SUCCESS:
            return { ...state, filter_fields: action.filter_fields, loading: false };

        case reportsConstants.STANDARD_REPORT_CRITERIA_FAILURE:
            return {
                error: action.message
            };
        case reportsConstants.STANDARD_REPORT_REQUEST:
            return {
                loading: true,
                items: [],
                standardReportDrilldownCallback: {}
            };
        case reportsConstants.STANDARD_REPORT_SUCCESS:
            return { ...state, standardReport: action.report, loading: false };

        case reportsConstants.STANDARD_REPORT_FIELDS:
            return { ...state, standardReportFields: action.fields };

        case reportsConstants.STANDARD_REPORT_DRILLDOWN_FIELDS:
            return { ...state, standardReportDrilldownFields: action.fields };

        case reportsConstants.STANDARD_REPORT_FAILURE:
            return {
                error: action.message
            };
        case reportsConstants.STANDARD_REPORT_DRILL_DOWN_SUCCESS:
            return { ...state, standardReportDrillDown: action.report, loading: false };

        case reportsConstants.STANDARD_REPORT_DRILL_DOWN_FAILURE:
            return {
                error: action.message
            };
        case reportsConstants.STANDARD_REPORT_DRILL_DOWN_CALLBACK_SUCCESS:
            return { ...state, standardReportDrilldownCallback: action.result, loading: false };

        case reportsConstants.STANDARD_REPORT_DRILL_DOWN_CALLBACK_FAILURE:
            return {
                error: action.message
            };
        case reportsConstants.CALLBACK_SET_EDIT_SUCCESS:
            return { ...state, callbackEditMessage: action.callback, loading: false };
        case reportsConstants.CALLBACK_SET_EDIT_FAILURE:
            return {
                error: action.message
            };
 
        case reportsConstants.CALLLISTING_REPORT_CRITERIA_REQUEST:
            return {
                loading: true,
                items: [],
                standardReportDrilldownCallback: {}
            };
        case reportsConstants.CALLLISTING_REPORT_CRITERIA_SUCCESS:
            return { ...state, filter_fields: action.filter_fields, loading: false };

        case reportsConstants.CALLLISTING_REPORT_CRITERIA_FAILURE:
            return {
                error: action.message
            };
 
        case reportsConstants.CALLLISTING_REPORT_REQUEST:
            return {
                loading: true,
                items: [],
                standardReportDrilldownCallback: {}
            };
        case reportsConstants.CALLLISTING_REPORT_DRILL_DOWN_FIELDS:
            return { ...state, calllistingReportDrilldownFields: action.fields };
        case reportsConstants.CALLLISTING_REPORT_DRILL_DOWN_SUCCESS:
            return { ...state, calllistingReportDrillDown: action.report, loading: false };
        case reportsConstants.CALLLISTING_REPORT_DRILL_DOWN_FAILURE:
            return { error: action.message };

        case reportsConstants.CALLLISTING_SET_COLUMN_FIELDS:
            return { ...state, calllistingReportSetColumnFields: action.fields };
            
        default:
            return state;
    }
}