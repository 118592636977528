import { displayConstants } from '../_constants';

export const displayActions = {
    setSidebar,
    setClientIdContext,
    setFilterFields
};

function setSidebar(sidebar) {
    return { type: displayConstants.SET_SIDEBAR, sidebar };
}

function setClientIdContext(clientId) {
    return dispatch => {
        if (!clientId) dispatch(failure(clientId));
        
        dispatch(success(clientId));

        // Storing client context in case of hard refresh
        localStorage.setItem('clientContext', clientId);

        function success(clientId) { return { type: displayConstants.SET_CLIENT_CONTEXT_SUCCESS, clientId } }
        function failure(error) { return { type: displayConstants.SET_CLIENT_CONTEXT_FAILURE, error } }
    }
}

function setFilterFields(requestFields, type = 'standardReportRequest') {
    const clientId = localStorage.getItem('clientContext');
    
    let typeItem = '';
    
    if (type === 'standardReportRequest' || type === 'callingListReportRequest') {
        typeItem = type + '-' + clientId;
    }
    
    return dispatch => {
        if (!requestFields) {
            dispatch(failure(requestFields, type));
        }
        
        localStorage.setItem(typeItem, JSON.stringify(requestFields));
        
        dispatch(success(requestFields, type));

        function success(requestFields, type) { 
            if (type === 'callingListReportRequest') {
                return { type: displayConstants.SET_CALLINGLIST_REPORT_REQ_SUCCESS, requestFields } 
            }
            return { type: displayConstants.SET_STANDARD_REPORT_REQ_SUCCESS, requestFields } 
        }

        function failure(error, type) {
            if (type === 'callingListReportRequest') {
                return { type: displayConstants.SET_CALLINGLIST_REPORT_REQ_FAILURE, requestFields } 
            }
            return { type: displayConstants.SET_STANDARD_REPORT_REQ_FAILURE, error } 
        }
    }
}

