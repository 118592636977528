export const scoreCardsConstants = {   
    MONTHY_REQUEST: 'MONTHY_REQUEST',
    MONTHY_SUCCESS: 'MONTHY_SUCCESS',
    MONTHY_FAILURE: 'MONTHY_FAILURE',

    CRITERIA_REQUEST: 'CRITERIA_REQUEST',
    CRITERIA_SUCCESS: 'CRITERIA_SUCCESS',
    CRITERIA_FAILURE: 'CRITERIA_FAILURE',

    COLOUR_CODES_REQUEST: 'COLOUR_REQUEST',
    COLOUR_CODES_SUCCESS: 'COLOUR_SUCCESS',
    COLOUR_CODES_FAILURE: 'COLOUR_FAILURE',

    EXPORT_FILE_REQUEST: 'EXPORT_FILE_REQUEST',
    EXPORT_FILE_SUCCESS: 'EXPORT_FILE_SUCCESS',
    EXPORT_FILE_FAILURE: 'EXPORT_FILE_FAILURE',

    MONTHLY_FIELDS: 'MONTHLY_FIELDS',
    QUARTERLY_FIELDS: 'QUARTERLY_FIELDS'
};
