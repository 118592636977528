export const clientConstants = {   
    GETALL_REQUEST: 'CLIENT_GETALL_REQUEST',
    GETALL_SUCCESS: 'CLIENT_GETALL_SUCCESS',
    GETALL_FAILURE: 'CLIENT_GETALL_FAILURE',

    GETONE_REQUEST: 'CLIENT_GETONE_REQUEST',
    GETONE_SUCCESS: 'CLIENT_GETONE_SUCCESS',
    GETONE_FAILURE: 'CLIENT_GETONE_FAILURE',

    GET_FIELDS_REQUEST: 'CLIENT_GET_FIELDS_REQUEST',
    GET_FIELDS_SUCCESS: 'CLIENT_GET_FIELDS_SUCCESS',
    GET_FIELDS_FAILURE: 'CLIENT_GET_FIELDS_FAILURE',

    SET_CLIENT_COUNTRY_SUCCESS: 'CLIENT_SET_CLIENT_COUNTRY_SUCCESS',
    SET_CLIENT_COUNTRY_FAILURE: 'CLIENT_SET_CLIENT_COUNTRY_FAILURE',

    SET_EDIT_SUCCESS: 'CLIENT_SET_EDIT_CLIENT_SUCCESS',
    SET_EDIT_FAILURE: 'CLIENT_SET_EDIT_CLIENT_FAILURE',

    NEW_CLIENT_SUCCESS: 'NEW_CLIENT_CLIENT_SUCCESS',
    NEW_CLIENT_FAILURE: 'NEW_CLIENT_CLIENT_FAILURE',

    SET_CLIENT_FIELD_SUCCESS: 'SET_CLIENT_FIELD_SUCCESS',
    SET_CLIENT_FIELD_SUCCESS: 'SET_CLIENT_FIELD_SUCCESS',

    DELETE_REQUEST: 'CLIENT_DELETE_REQUEST',
    DELETE_SUCCESS: 'CLIENT_DELETE_SUCCESS',
    DELETE_FAILURE: 'CLIENT_DELETE_FAILURE'
};