import { scoreCardsConstants } from '../_constants';
import { scoreCardsService } from '../_services';
import { alertActions } from '.';

import store from '../store';

export const scoreCardsActions = {
    getScoreCard,
    setFields
};


function getScoreCard(req, type) {
    const clientId = localStorage.getItem('clientContext');
    return dispatch => {
        dispatch(request());
        scoreCardsService.getScoreCard(req, type)
            .then(
                card => {
                    if (!card.error) {
                        if (card.data) {
                            dispatch(success(card.data));
                           
                            if (Object.keys(card.data).length > 0) {
                                const fields = Object.keys( card.data[Object.keys(card.data)[0]]['0_data'] );
                                
                                if (req.client_id == 74) {
                                    fields.unshift('contract_type');
                                }
                                
                                fields.unshift('office');
            
                                if (type == 'monthly') {
                                    dispatch( setFields( fields, scoreCardsConstants.MONTHLY_FIELDS ) );
                                } else {
                                    dispatch( setFields( fields, scoreCardsConstants.QUARTERLY_FIELDS ) );
                                }
                            }

                        }

                        if (card.pdf && card.xls) {
                            const exportFiles = { pdf: card.pdf, xls: card.xls};
                            if (card.xls_all) {
                                exportFiles['allXls'] = card.xls_all;
                            }
                            dispatch(successExport(exportFiles));
                        }

                        if (card.criteria) {
                            dispatch(successCriteria(card.criteria));
                        }

                        if (card.colour_codes) {
                            dispatch(successColourCodes(card.colour_codes));
                        }

                        localStorage.setItem('scoreCardRequestYear-'+clientId, req.year );
                    } else {
                        if (card.error.includes('Unauthorized') || card.error.includes('Failed to fetch')) {
                            window.location.href="/login";
                        }
                    }   
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error))
                    if (error === 'Unauthorized.' || error == 'TypeError: Failed to fetch') {
                        window.location.href="/login";
                    }
                }
            );
    };

    function request() { return { type: scoreCardsConstants.MONTHY_REQUEST } }
    function success(report) { return { type: scoreCardsConstants.MONTHY_SUCCESS, report } }
    function successCriteria(criteria) { return { type: scoreCardsConstants.CRITERIA_SUCCESS, criteria } }
    function successColourCodes(colour_codes) { return { type: scoreCardsConstants.COLOUR_CODES_SUCCESS, colour_codes } }
    function successExport(file) { return { type: scoreCardsConstants.EXPORT_FILE_SUCCESS, file } }
    function failure(error) { return { type: scoreCardsConstants.MONTHY_FAILURE, error } }
}


function setFields(fields, type) {
    
    fields = fields.filter(f => {
        if (typeof f !== 'object') {
            return !f.includes('_hidden')
        } else {
            return f.key.includes('_hidden');
        }
    });
    
    return { type,  fields }
}