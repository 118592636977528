import { userConstants } from '../_constants';
import { userService } from '../_services';
import { alertActions } from './';
import { history, hop } from '../_helpers';

export const userActions = {
    login,
    logout,
    getAll,
    getOne,
    getProfile,
    loginWithToken,
    saveSettings
};

function login(username, password) {
    return dispatch => {
        dispatch(request({ username }));

        userService.login(username, password)
            .then(
                user => { 
                    history.push('/');
                    history.go(0);

                    if (user) {
                        dispatch(success(user));
                        dispatch(setUserClients(user.clients))

                    }
  
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
    function setUserClients(clients) { return { type: userConstants.SET_CLIENTS, clients } }
}

function loginWithToken(token) {
    return dispatch => {
        dispatch(request({ token }));
        userService.getProfile(token)
            .then(
                user => { 
                    const token_obj = {
                        token,
                        expires_in: 60,
                        token_type: 'bearer'
                    };
           
                    user.token = token_obj;

                    if (user) {
                        localStorage.setItem('user', JSON.stringify(user));
                        dispatch(success(user));
                        dispatch(setUserClients(user.clients));
                    }

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
    function setUserClients(clients) { return { type: userConstants.SET_CLIENTS, clients } }
}

function logout(token) {
    userService.logout(token);
    return { type: userConstants.LOGOUT };
}



function saveSettings(userId, settings) {
    if (userId) {
        userService.saveSettings(userId, JSON.stringify(settings));
        return { settings: userConstants.SAVE_SETTINGS };
    }
}

function getAll() {
    return dispatch => {
        dispatch(request());

        userService.getAll()
            .then(
                users =>  { 
                    dispatch(success(users))
                },
                error => { 
                    dispatch(failure(error));
                    dispatch(alertActions.error(error))
                }
            );
    };

    function request() { return { type: userConstants.GETALL_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_FAILURE, error } }
}

function getOne( user_id ) {
    return dispatch => {
        dispatch(request());

        userService.getOne( user_id )
            .then(
                user =>  { 
                    dispatch(success(user))
                },
                error => { 
                    dispatch(failure(error));
                    dispatch(alertActions.error(error))
                }
            );
    };

    function request() { return { type: userConstants.GETONE_REQUEST } }
    function success(user) { return { type: userConstants.GETONE_SUCCESS, user } }
    function failure(error) { return { type: userConstants.GETONE_FAILURE, error } }
}

function getProfile( token ) {
    return dispatch => {
        dispatch(request());

        userService.getProfile( token )
            .then(
                user =>  { 
                    dispatch(success(user))
                },
                error => { 
                    dispatch(failure(error));
                    dispatch(alertActions.error(error))
                    
                        if(error.message === 'Unauthorized.') {
                            logout();
                            window.location.href="/login";
                        }
                    
                }
            );
    };

    function request() { return { type: userConstants.GETPROFILE_REQUEST } }
    function success(user) { return { type: userConstants.GETPROFILE_SUCCESS, user } }
    function failure(error) { return { type: userConstants.GETPROFILE_FAILURE, error } }
}