import { clientConstants } from '../_constants';
import { clientService } from '../_services';
import { alertActions } from './';

export const clientActions = {
    getAll,
    getOne,
    getFields,
    saveClient,
    setClientCountry,
    setAutoInsertMaintenanceDataId,
    editClient,
    setClientBanner,
    setClientLogo,
    deleteClient
};

function getAll() {
    return dispatch => {
        dispatch(request());

        clientService.getAll()
            .then(
                clients =>  { 
                    if (!clients.error) {
                        dispatch(success(clients))
                    } else {
                        if (clients.error.includes('Unauthorized')) {
                            window.location.href="/login";
                        }
                    }
                },
                error => { 
                    dispatch(failure(error));
                    // dispatch(alertActions.error(error))

                    if (error === 'Unauthorized.') {
                        window.location.href="/login";
                    }
                }
            );
    };

    function request() { return { type: clientConstants.GETALL_REQUEST } }
    function success(clients) { return { type: clientConstants.GETALL_SUCCESS, clients } }
    function failure(error) { return { type: clientConstants.GETALL_FAILURE, error } }
}

function getOne(id) {
    return dispatch => {
        dispatch(request());

        clientService.getOne(id)
            .then(
                client => dispatch(success(client)),
                error => { 
                    dispatch(failure(error));
                    // dispatch(alertActions.error(error))

                    if (error === 'Unauthorized.') {
                        window.location.href="/login";
                    }
                }
            );
    };

    function request() { return { type: clientConstants.GETONE_REQUEST } }
    function success(client) { return { type: clientConstants.GETONE_SUCCESS, client } }
    function failure(error) { return { type: clientConstants.GETONE_FAILURE, error } }
}

function getFields(){
    return dispatch => {
        dispatch(request());

        clientService.getFields()
            .then(
                client => dispatch(success(client)),
                error => { 
                    dispatch(failure(error));
                    // dispatch(alertActions.error(error))

                    if (error === 'Unauthorized.') {
                        window.location.href="/login";
                    }
                }
            );
    };

    function request() { return { type: clientConstants.GET_FIELDS_REQUEST } }
    function success(client) { return { type: clientConstants.GET_FIELDS_SUCCESS, client } }
    function failure(error) { return { type: clientConstants.GET_FIELDS_FAILURE, error } }
}

function setClientCountry(client, country_id) {
    return dispatch => {
        if (!client) dispatch(failure(client));
        client.client_fields.country_id = country_id;
        dispatch(success(client));

        function success(client) { return { type: clientConstants.SET_CLIENT_COUNTRY_SUCCESS, client } }
        function failure(error) { return { type: clientConstants.SET_CLIENT_COUNTRY_FAILURE, error } }
    }
}

function setAutoInsertMaintenanceDataId(client, id) {
    return dispatch => {
        if (!client) dispatch(failure(client));
        client.client_fields.auto_insert_maintenance = id;
        dispatch(success(client));

        function success(client) { return { type: clientConstants.SET_CLIENT_FIELD_SUCCESS, client } }
        function failure(error) { return { type: clientConstants.SET_CLIENT_FIELD_FAILURE, error } }
    }
}

function setClientBanner(client, file) {
    return dispatch => {
        if (!client) dispatch(failure(client));
        client.client_fields.banner = file;

        const name = file.name.substring(0, file.name.lastIndexOf('.')); // get file name
        const ext = file.name.substring(file.name.lastIndexOf('.')); // Concat with file extension.
        client.client_fields.banner_new = new File([file], name + ext,  { type: file.type});

        dispatch(success(client));

        function success(client) { return { type: clientConstants.SET_CLIENT_FIELD_SUCCESS, client } }
        function failure(error) { return { type: clientConstants.SET_CLIENT_FIELD_FAILURE, error } }
    }
}

function setClientLogo(client, file) {
    return dispatch => {
        if (!client) dispatch(failure(client));
        client.client_fields.logo = file;
        const name = file.name.substring(0, file.name.lastIndexOf('.')); // get file name
        const ext = file.name.substring(file.name.lastIndexOf('.')); // Concat with file extension.
        client.client_fields.logo_new = new File([file], name + ext,  { type: file.type});

        dispatch(success(client));

        function success(client) { return { type: clientConstants.SET_CLIENT_FIELD_SUCCESS, client } }
        function failure(error) { return { type: clientConstants.SET_CLIENT_FIELD_FAILURE, error } }
    }
}

function editClient(client) {
    return dispatch => {
        if (!client) dispatch(failure(client));
        clientService.saveClient(client)
        .then(
            client =>  {
                dispatch(success(client)); 
                dispatch(alertActions.success(client.message));
            }, 
            error => { 
                dispatch(failure(error));
                // dispatch(alertActions.error(error))

                if (error === 'Unauthorized.') {
                    window.location.href="/login";
                }
            }
        );

        function success(client) { return { type: clientConstants.SET_EDIT_SUCCESS, client } }
        function failure(error) { return { type: clientConstants.SET_EDIT_FAILURE, error } }
    }
}

function saveClient() {
    return dispatch => {
        clientService.saveClient()
        .then(
            client =>  {
                dispatch(success()); 
                dispatch(alertActions.success());
            }, 
            error => { 
                dispatch(failure(error));
                // dispatch(alertActions.error(error))

                if (error === 'Unauthorized.') {
                    window.location.href="/login";
                }
            }
        );

        function success(client) { return { type: clientConstants.NEW_CLIENT_SUCCESS } }
        function failure(error) { return { type: clientConstants.NEW_CLIENT_FAILURE, error } }
    }
}

function deleteClient( client_id ) {
    return dispatch => {
        dispatch(request());

        clientService.deleteClient( client_id )
            .then(
                deleteclient =>  { 
                    dispatch(success(deleteclient))
                },
                error => { 
                    dispatch(failure(error));
                    dispatch(alertActions.error(error))
                }
            );
    };

    function request() { return { type: clientConstants.DELETE_REQUEST } }
    function success(deleteclient) { deleteclient.success=true; return { type: clientConstants.DELETE_SUCCESS, deleteclient } }
    function failure(error) { return { type: clientConstants.DELETE_FAILURE, error } }
}
