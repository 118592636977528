import config from '../config';
import { preparePostRequest, handleResponse } from '../_helpers';

export const buildingsService = {
    upload,
    submit
};


function upload(request, client_id) {
    const requestOptions = preparePostRequest(request);

    return fetch(`${config.apiUrl}importBuildings/client/${client_id}`, requestOptions).then(handleResponse);
}

function submit(request, client_id, code) {
    const requestOptions = preparePostRequest(request);

    return fetch(`${config.apiUrl}importBuildings/client/${client_id}/code/${code}`, requestOptions).then(handleResponse);
}


