export const devicesConstants = {
   UPLOAD_REQUEST: 'UPLOAD_REQUEST',
   UPLOAD_SUCCESS: 'UPLOAD_SUCCESS',
   UPLOAD_FAILURE: 'UPLOAD_FAILURE',

   SUBMISSION_REQUEST: 'SUBMISSION_REQUEST',
   SUBMISSION_SUCCESS: 'SUBMISSION_SUCCESS',
   SUBMISSION_FAILURE: 'SUBMISSION_FAILURE',

   RENDER_RESULTS: 'RENDER_RESULTS'
};