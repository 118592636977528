import { elevatorConsultingCompanyConstants } from '../_constants/elevator-consulting-company.constants';

export function elevatorConsultingCompany(state = {}, action) {

    switch (action.type) {
        case elevatorConsultingCompanyConstants.GETALL_REQUEST:
            return {
                loading: true,
                items: []
            };
        case elevatorConsultingCompanyConstants.GETALL_SUCCESS:
            return { items: action.ecc, loading: false };
        case elevatorConsultingCompanyConstants.GETALL_FAILURE:
            return {
                error: action.error, loading: false
            };

        case elevatorConsultingCompanyConstants.GETONE_REQUEST:
            return {
                loading: true,
                items: []
            };
        case elevatorConsultingCompanyConstants.GETONE_SUCCESS:
            return { ecc: action.ecc };
        case elevatorConsultingCompanyConstants.GETONE_FAILURE:
            return {
                error: action.error
            };

        case elevatorConsultingCompanyConstants.UPDATE_SUCCESS:
            return { ecc: action.ecc, success: true };
        case elevatorConsultingCompanyConstants.UPDATE_FAILURE:
            return {
                error: action.error, success: false
            };

        case elevatorConsultingCompanyConstants.NEW_SUCCESS:
            return { new_id:action.createnew.id, success: true };
        case elevatorConsultingCompanyConstants.NEW_FAILURE:
            return {
                error: action.error, success: false
            };

        case elevatorConsultingCompanyConstants.DELETE_REQUEST:
            return {
                loading: true,
            };
        case elevatorConsultingCompanyConstants.DELETE_SUCCESS:
            return { deleteecc: action.deleteecc, loading: false, };
        case elevatorConsultingCompanyConstants.DELETE_FAILURE:
            return {
                error: action.error, loading: false
            }; 
 
        default:
            return state;
    }
}
