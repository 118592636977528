import config from '../config';
import { preparePostRequest, handleResponse } from '../_helpers';
import qs from 'qs'
import axios from 'axios'

export const scoreCardsService = {
    getScoreCard
};


function getScoreCard(request, type) {
    if (!type && request.hasOwnProperty('type')) {
        type = request.type;
    }

    delete request['type'];
    const requestOptions = preparePostRequest(request);

    return fetch(`${config.apiUrl}scorecard/${type}`, requestOptions).then(handleResponse);
}


