import { clientUserConstants } from '../_constants/clientuser.constants';

export function clientusers(state = {}, action) {

    switch (action.type) {
        case clientUserConstants.GETALL_REQUEST:
            return {
                loading: true,
                items: []
            };
        case clientUserConstants.GETALL_SUCCESS:
            return { items: action.clientusers };
        case clientUserConstants.GETALL_FAILURE:
            return {
                error: action.error
            };

        case clientUserConstants.GETONE_REQUEST:
            return {
                loading: true,
                items: []
            };
        case clientUserConstants.GETONE_SUCCESS:
            return { clientuser: action.clientuser };
        case clientUserConstants.GETONE_FAILURE:
            return {
                error: action.error
            };

        case clientUserConstants.UPDATE_SUCCESS:
            return { clientuser: action.clientuser, success: true };
        case clientUserConstants.UPDATE_FAILURE:
            return {
                error: action.error, success: false
            };

        case clientUserConstants.NEW_CLIENT_SUCCESS:
            return { new_user_id:action.createnew.user_id, success: true };
        case clientUserConstants.NEW_CLIENT_FAILURE:
            return {
                error: action.error, success: false
            };

        case clientUserConstants.GET_ASSETS_SUCCESS:
            return { assets: action.assets };
        case clientUserConstants.GET_ASSETS_FAILURE:
            return {
                error: action.error, success: false
            };

        case clientUserConstants.GET_USERFORM_SUCCESS:
            return { clientuser: action.clientuser };
        case clientUserConstants.GET_USERFORM_FAILURE:
            return {
                error: action.error, success: false
            };
 
        case clientUserConstants.DELETE_REQUEST:
            return {
                loading: true,
                // items: []
            };
        case clientUserConstants.DELETE_SUCCESS:
            return { deleteuser: action.deleteuser };
        case clientUserConstants.DELETE_FAILURE:
            return {
                error: action.error
            };
 
 
        default:
            return state;
    }
}