import { clientConstants } from '../_constants';

export function clients(state = [], action) {
    // console.log(action);
    switch (action.type) {
        case clientConstants.GETALL_REQUEST:
            return {
                loading: true,
                items: []
            };
        case clientConstants.GETALL_SUCCESS:
            return { items: action.clients };
        case clientConstants.GETONE_REQUEST:
            return {
                loading: true,
                client: {}
            };
        case clientConstants.GET_FIELDS_REQUEST:
            return {
                loading: true,
                client: {}
            };
        case clientConstants.GETONE_SUCCESS:
            return { details: action.client };
        case clientConstants.GET_FIELDS_SUCCESS:
            return { details: action.client };
        case clientConstants.GETALL_FAILURE:
            return {
                error: action.error
            };

        case clientConstants.GETONE_FAILURE:
            return {
                error: action.message
            };
        case clientConstants.GET_FIELDS_FAILURE:
            return {
                error: action.message
            };
        case clientConstants.SET_CLIENT_COUNTRY_SUCCESS:
            return {
                details: action.client
            };
        case clientConstants.SET_CLIENT_FIELD_SUCCESS:
            return {
                details: action.client
            };
        case clientConstants.SET_CLIENT_COUNTRY_FAILURE:
            return {
                error: action.message
            };
        case clientConstants.SET_CLIENT_FIELD_FAILURE:
            return {
                error: action.message
            };
        case clientConstants.SET_EDIT_SUCCESS:
            return {
                redirectToList: true,
                result: action.client
            }
        case clientConstants.SET_EDIT_FAILURE:
            return {
                error: action.message
            };

        case clientConstants.DELETE_REQUEST:
            return {
                loading: true,
                // items: []
            };
        case clientConstants.DELETE_SUCCESS:
            return { deleteclient: action.deleteclient };
        case clientConstants.DELETE_FAILURE:
            return {
                error: action.error
            };
 
        default:
            return state
    }
}